/**
 * Helper to load content from external resources
 */
import fetch from 'cross-fetch'
import yaml from 'js-yaml'

// Chooses between a random element in an array
// An "array" as an attribute
// Or a single attribute
// Whichever comes first
const contentPicker = (a, s)=> {
  if (a && a.length > 0)
    return randItem(a)
  else if (a && a.length === 0)
    return a
  else if (!a && s)
    return s
}

// Select a random item from array
const randItem = (items) => {
  const length = (items.length) ? items.length : 0
  if (length > 0)
    return items[Math.floor(Math.random() * length)]
  else 
    return null
}

// Fetch yaml
const fetchContent = async ( contentUrl, type='yaml' ) => {
  // console.log(fetchParams)
  return fetch(contentUrl)
  .then( async res => {
    if(type === 'yaml')
      return yaml.safeLoad(await res.text())
    if(type === 'json')
      return JSON.parse(await res.text())
    else
      return res.text()
  }).catch( e => console.error(e) )
}

export { fetchContent, randItem, contentPicker }
