import { useEffect, useState } from 'react'
import { fetchContent } from 'apis/FetchContent'

// Determines whether to load content from external source, or default (local) source, executes callbal 
export const useExternalContent = ( compContent, cb = null ) => {
  const { contentUrl } = compContent
  const [ content, setContent ] = useState(null)

  useEffect(() => {
    const whenMounted = async () => {
      let c = null
      if(contentUrl){
        // console.log(contentUrl)
        c = await fetchContent(contentUrl)
        c.isExtContent = true
      } else {
        c = compContent
        c.isExtContent = false
      }
      if (cb)
        setContent(await cb(c))
      else 
        setContent(c)
    }
    whenMounted()
  },[cb, compContent, contentUrl])

  return content
}
